.support-wrapper,
[role="tooltip"].popup-content.support-popup-content {
    --main-color: #e87a1e;
    --secondary-color: #002157;
    --light-color: #fdf9f5;

    height: 100%;
    width: 100%;
    .row {
        display: flex;
        align-items: center;
    }
    .col {
        display: flex;
        flex-direction: column;
    }
    .card {
        box-shadow: 0px 5px 15px 0px #00347026;
        background-color: #ffffff;
        border-radius: 6px;
    }
    .support-icon {
        display: block;
        height: 26px;
        width: 26px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer;

        &.support-icon-attach {
            background-image: url("../assets/attach.svg");
        }
        &.support-icon-send {
            background-image: url("../assets/send.svg");
        }
        &.support-icon-chat {
            background-image: url("../assets/chat.svg");
        }
        &.support-icon-arrow-right {
            background-image: url("../assets/arrow-right.svg");
        }
        &.support-icon-arrow-right-white {
            background-image: url("../assets/arrow-right-white.svg");
        }
        &.support-icon-remove {
            background-image: url("../assets/remove.svg");
        }
        &.support-icon-trash {
            background-image: url("../assets/trash.svg");
        }
        &.support-icon-options {
            background-image: url("../assets/options.svg");
        }
        &.support-icon-filters {
            -webkit-mask: url("../assets/filters.svg") no-repeat 100% 100%;
            -webkit-mask-size: contain;
            -webkit-mask-position: center;
            mask: url("../assets/filters.svg") no-repeat 100% 100%;
            mask-size: contain;
            mask-position: center;
            background-color: var(--main-color);
        }
        &.support-icon-flag {
            background-image: url("../assets/flag.svg");
        }
        &.support-icon-speech {
            background-image: url("../assets/speech.svg");
        }
        &.support-icon-close {
            background-image: url("../assets/close.svg");
        }
        &:active {
            transform: scale(0.98);
        }
    }

    @font-face {
        font-family: "Montserrat";
        src: url("../fonts/Montserrat-Thin.ttf") format("truetype");
        font-style: normal;
        font-weight: 100;
    }
    @font-face {
        font-family: "Montserrat";
        src: url("../fonts/Montserrat-Light.ttf") format("truetype");
        font-style: normal;
        font-weight: 300;
    }
    @font-face {
        font-family: "Montserrat";
        src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
        font-style: normal;
        font-weight: 400;
    }
    @font-face {
        font-family: "Montserrat";
        src: url("../fonts/Montserrat-Medium.ttf") format("truetype");
        font-style: normal;
        font-weight: 500;
    }
    @font-face {
        font-family: "Montserrat";
        src: url("../fonts/Montserrat-Bold.ttf") format("truetype");
        font-style: normal;
        font-weight: 700;
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a,
    ul,
    ol,
    li,
    div,
    span,
    input,
    textarea {
        font-family: "Montserrat", sans-serif;
        color: #050508;
        font-weight: 400;
    }
}

[role="tooltip"].popup-content.support-popup-content,
.support-wrapper .support-container {
    .raised-button-container {
        display: flex;
        margin: 10px 0px;
        height: 35px;
        padding: 0px 25px;
        align-items: center;
        justify-content: center;
        background-color: var(--main-color);
        color: #ffffff;
        box-shadow: 0px 1px 4px rgba(0, 52, 112, 0.15);
        border-radius: 5px;
        cursor: pointer;
        font-size: 13px;
        font-weight: 700;
        white-space: nowrap;
        &.cancel {
            background-color: var(--light-color);
            color: var(--secondary-color);
            margin-right: 10px;
        }
        &.disabled {
            opacity: 0.7;
            pointer-events: none;
        }
        &:active {
            transform: scale(0.98);
        }
    }

    height: 100%;
    width: 100%;
    &.row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .support-tickets-container {
        width: calc(100% * 0.25 - 0.5vw);
        height: 100%;
        .support-tickets-header {
            padding: 0 0.5vw;
            h2 {
                font-size: 1.125rem;
                line-height: 2rem;
                font-weight: 700;
                margin: 0;
                margin-right: auto;
            }
            .raised-button-container {
                text-transform: uppercase;
                p {
                    color: #ffffff;
                    font-weight: 700;
                }
                .support-icon-chat {
                    margin-left: 15px;
                }
            }
        }
        .support-tickets-content {
            height: calc(100% - 65px);
            overflow: auto;
            .row-filters {
                padding: 10px 0.5vw 20px;
                input {
                    flex: 1;
                    border: 1px solid rgba(0, 52, 112, 0.3);
                    border-radius: 6px;
                    padding: 10px;
                    font-size: 13px;
                    &:active,
                    &:focus {
                        outline: none;
                    }
                }
                .support-icon-filters-wrapper {
                    justify-content: center;
                    box-shadow: 0px 1px 4px rgba(0, 52, 112, 0.15);
                    background-color: var(--light-color);
                    border-radius: 6px;
                    height: 38px;
                    width: 38px;
                    margin-left: 0.5vw;
                    position: relative;
                    &.active::after {
                        content: "";
                        height: 0.75rem;
                        width: 0.75rem;
                        background-color: #00cb5e;
                        border-radius: 50%;
                        box-shadow: 0px 0px 5px #00cb5e;
                        position: absolute;
                        top: -0.125rem;
                        right: -0.25rem;
                    }
                }
            }
            .support-checkboxes-container.select-all .label-container {
                margin-left: 0.5vw;
            }
            .support-checkboxes-container .single-checkbox {
                margin-left: 0.5vw;
                .checkbox-outer {
                    margin-right: 0;
                }
            }
            div.empty {
                height: 100%;
                padding: 0 1vw;
                p {
                    text-align: center;
                }
            }
            .divider {
                height: 1px;
                width: calc(100% - 1vw);
                background-color: var(--secondary-color);
                margin: 0 0.5vw 10px;
            }
            .row-ticket {
                margin-bottom: 10px;
            }
        }
    }

    .support-content {
        height: 100%;
        width: calc(100% * 0.75 - 0.5vw);
    }
}

[role="tooltip"].popup-content.support-popup-content {
    height: auto;
    width: 250px;
    .support-popup-filters-container {
        padding: 0 5px;
        .support-popup-filters-header {
            justify-content: space-between;
            h3 {
                font-size: 0.75rem;
                font-weight: 500;
            }
            .support-icon.support-icon-close {
                height: 1rem;
                width: 1rem;
            }
        }
        // .support-popup-filters-content {
        // }

        .support-popup-filters-footer {
            margin: 10px -10px -5px;
            padding: 0 10px;
            justify-content: space-between;
            box-shadow: 0px -1px 4px rgba(0, 52, 112, 0.15);
            p {
                cursor: pointer;
                font-size: 0.875rem;
                &:active {
                    transform: scale(0.97);
                }
            }
        }
    }
    .support-popup-options-container {
        padding: 0 5px;
        .support-popup-options-header {
            justify-content: space-between;
            h3 {
                font-size: 0.75rem;
                font-weight: 500;
            }
            .support-icon.support-icon-close {
                height: 1rem;
                width: 1rem;
            }
        }
        .support-popup-options-content {
            .row-action {
                cursor: pointer;
                justify-content: space-between;
                p {
                    font-size: 0.875rem;
                    font-weight: 500;
                }
                .support-icon {
                    margin-left: 30px;
                }
                &:not(:last-child) {
                    border-bottom: 1px solid #000000;
                }
                &:active {
                    transform: scale(0.97);
                }
            }
        }
    }
}
